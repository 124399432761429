import { useEffect, useState } from "react";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { showAsteriskIfEmpty } from '../../utils/helpers/asterisk';

interface PassDetailsProps {
  operationalNeed: string;
  setOperationalNeed: (newOperationalNeed: string) => void;
  airportId: string;
  setAirportId: (newAirportId: string) => void;
  rangeType: string;
  setRangeType: (newRangeType: string) => void;
  startTime: string;
  setStartTime: (newStartTime: string) => void;
  endTime: string;
  setEndTime: (newEndTime: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const PassDetails = (props: PassDetailsProps) => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [airports, setAirports] = useState<any[]>([]);

  useEffect(() => {
    props.setInvalidForm(
      !props.operationalNeed ||
        !props.airportId ||
        !props.rangeType ||
        !props.startTime ||
        !props.endTime
    );
  }, [
    props.operationalNeed,
    props.airportId,
    props.rangeType,
    props.startTime,
    props.endTime,
  ]);

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      setLoading(true);
      api
        .getAirports()
        .then((res) => {
          setAirports(res.data.data);
          props.setAirportId(res.data.data[0].id);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [auth.loading]);
  
  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-4">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-orange px-3 pb-1.5 pt-2.5">
            <label htmlFor="operational-need" className="block text-xs font-medium text-gray-900">
              Operational Need{showAsteriskIfEmpty(props.operationalNeed)}
            </label>
            <textarea
              id="operational-need"
              name="operational-need"
              autoComplete="new-operational-need"
              required
              value={props.operationalNeed}
              onChange={(e) => props.setOperationalNeed(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              rows={2}
              placeholder="Operational Need"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-orange px-3 pb-1.5 pt-2.5">
            <label htmlFor="airport" className="block text-xs font-medium text-gray-900">
              Airport
            </label>
            <select
              id="airport"
              name="airport"
              value={props.airportId}
              onChange={(e) => props.setAirportId(e.target.value)}
              disabled={loading || airports.length === 0}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              {airports.map((airport) => (
                <option key={airport.id} value={airport.id}>
                  {airport.name} ({airport.timezone})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-4">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-orange px-3 pb-1.5 pt-2.5">
            <label htmlFor="pass-duration" className="block text-xs font-medium text-gray-900">
              Pass Duration
            </label>
            <select
              id="pass-duration"
              name="pass-duration"
              value={props.rangeType}
              onChange={(e) => props.setRangeType(e.target.value)}
              disabled
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="24 Hours">24 Hours</option>
              <option value="1 Week">1 Week</option>
              <option value="1 Month">1 Month</option>
              <option value="Custom">Custom</option>
            </select>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-orange px-3 pb-1.5 pt-2.5">
            <label htmlFor="start-time" className="block text-xs font-medium text-gray-900">
              Start Time
            </label>
            <input
              id="start-time"
              name="start-time"
              type="datetime-local"
              autoComplete="new-start-time"
              required
              value={props.startTime}
              onChange={(e) => props.setStartTime(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-orange px-3 pb-1.5 pt-2.5">
            <label htmlFor="end-time" className="block text-xs font-medium text-gray-900">
              End Time
            </label>
            <input
              id="end-time"
              name="end-time"
              type="datetime-local"
              autoComplete="new-end-time"
              required
              value={props.endTime}
              onChange={(e) => props.setEndTime(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled={props.rangeType !== "Custom"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};