import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  XIcon,
} from "@heroicons/react/outline";
import debounce from "lodash.debounce";
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import { getBase64 } from "../../../utils/images";
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function AccountSettings() {
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const [emailInUseError, setEmailInUseError] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [emailUpdatedSuccess, setEmailUpdatedSuccess] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [visitorFlagged, setVisitorFlagged] = useState(false);
  const [visitorBlocked, setVisitorBlocked] = useState(false);
  const [userPhotoUrl, setUserPhotoUrl] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [initialEmail, setInitialEmail] = useState<string>("");
  const [updatedEmail, setUpdatedEmail] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [suggestedAddress, setSuggestedAddress] = useState<string>("");

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      fetchVisitorInfo();
    }
  }, [auth.loading]);

  const fetchVisitorInfo = async () => {
    api
      .getVisitorInfo()
      .then((res) => {
        const visitor = res.data;
        setUserPhotoUrl(visitor.image ?? "");
        setFirstName(visitor.firstName ?? "");
        setLastName(visitor.lastName ?? "");
        setCompany(visitor.company ?? "");
        setMobileNumber(visitor.phone ?? "");
        setInitialEmail(visitor.email ?? "");
        setUpdatedEmail(visitor.email ?? "");
        setDob(visitor.dob ?? "");
        setAddress(visitor.address ?? "");

        if (visitor.status === 2) {
          setVisitorFlagged(true);
        } else if (visitor.status === 3) {
          setVisitorBlocked(true);
        }
      })
      .catch((error) =>
        console.error("Error occurred while getting visitor info - ", error)
      )
      .finally(() => setLoading(false));
  };

  const updateProfile = async () => {
    setLoading(true);
    setUpdateInProgress(true);
    try {
      const updatedProfile = await api.updateProfile(auth.user.id, {
        firstName,
        lastName,
        email: updatedEmail,
        phone: mobileNumber,
        company,
        address,
      });

      if (updatedProfile.data) {
        auth.updateUser(updatedProfile.data);
      }
      if (updatedEmail !== initialEmail) {
        setEmailUpdatedSuccess(true);
      }
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
      fetchVisitorInfo();
    } catch (error: any) {
      console.error("Error occurred while updating profile - ", error);

      if (
        Object.prototype.hasOwnProperty.call(error, "response") &&
        error.response.status === 409
      ) {
        setEmailInUseError(true);
        setTimeout(() => setEmailInUseError(false), 3000);
      } else {
        setError(true);
        setTimeout(() => setError(false), 3000);
      }
    }
    setLoading(false);
    setUpdateInProgress(false);
  };

  const updateUserImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setUpdateInProgress(true);

    try {
      if (e.target.files && e.target.files.length > 0) {
        const imageFile = e.target.files[0];
        const base64 = await getBase64(imageFile);
        const updatedProfile = await api.updateProfile(auth.user.id, {
          image: base64,
        });
        auth.updateUser(updatedProfile.data);
        setUserPhotoUrl(URL.createObjectURL(e.target.files[0]));
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
      } else {
        setError(true);
        setTimeout(() => setError(false), 3000);
      }
    } catch (error) {
      console.error("Error occurred while updating user image - ", error);
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
    setLoading(false);
    setUpdateInProgress(false);
  };

  const updatePassword = async () => {
    setLoading(true);
    setUpdateInProgress(true);
    try {
      const updatedPassword = await api.changePassword(
        auth.user.id,
        currentPassword,
        newPassword
      );
      auth.updateUser(updatedPassword.data);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error("Error occurred while updating password - ", error);
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
    setLoading(false);
    setUpdateInProgress(false);
  };

  const resetPassword = async () => {
    setLoading(true);
    setUpdateInProgress(true);
    try {
      await api.sendResetPasswordEmail(initialEmail);
      setResetSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      console.error(
        "Error occurred while sending password reset email - ",
        error
      );
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
    setLoading(false);
    setUpdateInProgress(false);
  };

  const changeAddress = async (newAddress: string) => {
    setAddress(newAddress);
    // Validate address
    if (newAddress && newAddress !== suggestedAddress) {
      debouncedValidateAddress(newAddress);
    } else {
      setSuggestedAddress("");
    }
  };

  const debouncedValidateAddress = React.useRef(
    debounce(async (addressToValidate: string) => {
      const addressRes = await api.validateVisitorAddress(addressToValidate);
      setSuggestedAddress(addressRes.data);
    }, 1500)
  ).current;

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <section aria-labelledby="account-details-heading">
        <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <h2 id="account-details-heading" className="text-lg leading-6 font-medium text-gray-900 text-center">
              Account Details
            </h2>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="first-name" className="block text-xs font-medium text-gray-900">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    required
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="last-name" className="block text-xs font-medium text-gray-900">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="email" className="block text-xs font-medium text-gray-900">
                    Email address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    value={updatedEmail}
                    onChange={(e) => setUpdatedEmail(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="mobile-number" className="block text-xs font-medium text-gray-900">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    name="mobile-number"
                    id="mobile-number"
                    autoComplete="tel"
                    required
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="company" className="block text-xs font-medium text-gray-900">
                    Company
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    autoComplete="organization"
                    required
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                <label htmlFor="address" className="block text-xs font-medium text-gray-900">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  autoComplete="street-address"
                  required
                  value={address}
                  onChange={(e) => changeAddress(e.target.value)}
                  className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>
            </div>
            
            <div className="col-span-6 flex justify-center">
              <button
                onClick={updateProfile}
                disabled={loading || visitorFlagged || visitorBlocked}
                className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </section>

      <section aria-labelledby="change-password-heading">
        <div className="shadow sm:rounded-md sm:overflow-hidden mx-2 sm:mx-0">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <h2 id="change-password-heading" className="text-lg leading-6 font-medium text-gray-900 text-center">
              Change Password
            </h2>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="current-password" className="block text-xs font-medium text-gray-900">
                    Current Password
                  </label>
                  <input
                    type="password"
                    name="current-password"
                    id="current-password"
                    autoComplete="current-password"
                    required
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="new-password" className="block text-xs font-medium text-gray-900">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="new-password"
                    id="new-password"
                    autoComplete="new-password"
                    required
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
                  <label htmlFor="confirm-password" className="block text-xs font-medium text-gray-900">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    autoComplete="new-password"
                    required
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
                  />
                </div>
              </div>

              <div className="col-span-6 flex justify-center">
                <button
                  onClick={updatePassword}
                  disabled={loading || !newPassword || !confirmPassword || !currentPassword || newPassword !== confirmPassword || visitorFlagged || visitorBlocked}
                  className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

        <div
          aria-live="assertive"
          className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
        >
          <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={success}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully updated account details
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={emailUpdatedSuccess}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully updated email address
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Check your inbox/spam folder for a verification email to
                        verify your new email address.
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setEmailUpdatedSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={resetSuccess}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Successfully sent password reset email
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Check your inbox/spam folder
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setResetSuccess(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={error}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <XIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        An error occurred
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please try again or contact support
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={emailInUseError}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <XIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Error - that email is already in use
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Please try again with a different email
                      </p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex">
                      <button
                        type="button"
                        className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        onClick={() => {
                          setEmailInUseError(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
            <Transition
              show={updateInProgress}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <DotsCircleHorizontalIcon
                        className="h-6 w-6 text-blue-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Loading...
                      </p>
                      <p className="mt-1 text-sm text-gray-500">Please wait</p>
                    </div>
                    <div className="ml-4 flex-shrink-0 flex"></div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
        </div>
  );
}