import React, { useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import api from "../helpers/api";

interface ErrorObject {
  code: string;
  message: string;
}
interface AuthContextType {
  user: any;
  token: string;
  loading: boolean;
  signUpWithEmail: (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phone: string,
    company: string,
    dob: string,
    address: string
  ) => Promise<ErrorObject | void>;
  signInWithEmail: (
    email: string,
    password: string
  ) => Promise<ErrorObject | void>;
  signout: (callback?: VoidFunction) => void;
  updateToken: (newToken: string) => void;
  updateUser: (newUser?: any) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [token, setToken] = React.useState<string>(
    localStorage.getItem("token") ?? ""
  );
  const [user, setUser] = React.useState<any>(
    JSON.parse(localStorage.getItem("currentUser") || "null")
  );
  const [loading, setLoading] = useState<boolean>(false);

  const signUpWithEmail = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    phone: string,
    company: string,
    dob: string,
    address: string
  ): Promise<void | ErrorObject> => {
    setLoading(true);
    const res = await api.register({
      email: email.toLowerCase(),
      password,
      firstName,
      lastName,
      phone,
      company,
      dob,
      address,
    });

    const user = res.data;
    setLoading(false);
    navigate(`/verify?email=${user.email}&&pswd=${user.hashed_password}`);
  };

  const signInWithEmail = async (
    email: string,
    password: string
  ): Promise<void | ErrorObject> => {
    setLoading(true);
    const res = await api.login(email.toLowerCase(), password);
    if (res.data.verified) {
      const { token, candidate } = res.data;
      updateUser(candidate);
      updateToken(token);
      api.updateClient(token);
    } else if (Object.prototype.hasOwnProperty.call(res.data, "redirect")) {
      navigate(res.data.redirect);
    }
    setLoading(false);
  };

  const signout = (callback?: VoidFunction) => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    if (callback) {
      callback();
    }
  };

  const updateToken = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  const updateUser = (newUser?: any) => {
    setLoading(true);
    if (newUser) {
      setUser(newUser);
      localStorage.setItem("currentUser", JSON.stringify(newUser));
      setLoading(false);
    } else {
      api
        .getVisitorInfo()
        .then((res) => {
          const newUser = res.data.data;
          setUser(newUser);
          localStorage.setItem("currentUser", JSON.stringify(newUser));
        })
        .catch((error) => console.error("Error occurred while updating user"))
        .finally(() => setLoading(false));
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      token,
      loading,
      signUpWithEmail,
      signInWithEmail,
      signout,
      updateToken,
      updateUser,
    }),
    [token, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (
    auth.user &&
    auth.user.email &&
    auth.user.hashed_password &&
    !auth.user.verified
  ) {
    return (
      <Navigate
        to={`/verify?email=${auth.user.email}&&pswd=${auth.user.hashed_password}`}
        replace
        state={{ from: location.pathname }}
      />
    );
  } else if (!auth.token || !auth.user) {
    auth.signout();
    return (
      <Navigate to="/signin" replace state={{ from: location.pathname }} />
    );
  }

  return children;
}
