import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  IdentificationIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import G99Favicon from "../../assets/img/g99-favicon.png";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { FacePhoto } from "../CreateVisitorPass/FacePhoto";
import { IDPhoto } from "../CreateVisitorPass/IDPhoto";
import { ReviewPass } from "./ReviewPass";
import { YourDetails } from "./YourDetails";

export const VerifySponsor = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [linkInvalid, setLinkInvalid] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [approveSuccess, setApproveSuccess] = useState<boolean>(false);
  const [declineSuccess, setDeclineSuccess] = useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(false);
  const [visitorPassId, setVisitorPassId] = useState<string>("");
  const [paramsEmail, setParamsEmail] = useState<string>("");
  const [paramsMobileNumber, setParamsMobileNumber] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] =
    useState<string>("1");
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [sponsorCompany, setSponsorCompany] = useState<string>("");

  const [sponsorEmail, setSponsorEmail] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [idPhotoFront, setIDPhotoFront] = useState<string>("");
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(1);

  const getStepStatus = (stepId: number) => {
    return currentStep === stepId
      ? "current"
      : currentStep > stepId
      ? "complete"
      : "upcoming";
  };

  useEffect(() => {
    if (currentStep === 1) setInvalidForm(false);
  }, [currentStep]);

  const steps = [
    {
      id: 1,
      name: "Pass Details",
      status: getStepStatus(1),
      icon: <UserCircleIcon className="h-6 w-auto" />,
      component: (
        <ReviewPass visitorPassId={visitorPassId} sponsorDetailsHidden />
      ),
    },
    {
      id: 2,
      name: "Your Details",
      status: getStepStatus(2),
      icon: <UserCircleIcon className="h-6 w-auto" />,
      component: (
        <YourDetails
          sponsorName={sponsorName}
          setSponsorName={setSponsorName}
          sponsorAsicId={sponsorAsicId}
          setSponsorAsicId={setSponsorAsicId}
          sponsorAsicExpiryMonth={sponsorAsicExpiryMonth}
          setSponsorAsicExpiryMonth={setSponsorAsicExpiryMonth}
          sponsorAsicExpiryYear={sponsorAsicExpiryYear}
          setSponsorAsicExpiryYear={setSponsorAsicExpiryYear}
          sponsorCompany={sponsorCompany}
          setSponsorCompany={setSponsorCompany}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 3,
      name: "ID Photo",
      status: getStepStatus(3),
      icon: <IdentificationIcon className="h-6 w-auto" />,
      component: (
        <IDPhoto
          idPhotoFront={idPhotoFront}
          setIdPhotoFront={setIDPhotoFront}
          setInvalidForm={setInvalidForm}
          idName={"ASIC ID"}
        />
      ),
    },
    {
      id: 4,
      name: "Face Photo",
      status: getStepStatus(4),
      icon: <CameraIcon className="h-6 w-auto" />,
      component: (
        <FacePhoto
          facePhoto={facePhoto}
          setFacePhoto={setFacePhoto}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 5,
      name: "Review Pass",
      status: getStepStatus(5),
      icon: <CheckCircleIcon className="h-6 w-auto" />,
      component: (
        <ReviewPass
          visitorPassId={visitorPassId}
          sponsorDetailsHidden={false}
        />
      ),
    },
  ];

  useEffect(() => {
    if (searchParams) {
      setVisitorPassId(searchParams.get("visitorPassId") ?? "");
      setParamsEmail(searchParams.get("email") ?? "");
      setParamsMobileNumber(searchParams.get("mobileNumber") ?? "");
      setToken(searchParams.get("token") ?? "");
      auth.updateToken(searchParams.get("token") ?? "");
      api.updateClient(token);
    }
  }, [searchParams]);

  useEffect(() => {
    if (token !== "" && visitorPassId !== "") {
      auth.updateToken(token);
      api.updateClient(token);
      fetchVisitorPass();
    }
  }, [token, visitorPassId]);

  const fetchVisitorPass = async () => {
    try {
      const visitorPassResponse = await api.getVisitorPassById(visitorPassId);
      const visitorPass = visitorPassResponse.data;

      if (
        Boolean(paramsEmail && sponsorEmail && paramsEmail !== sponsorEmail) ||
        Boolean(
          paramsMobileNumber &&
            sponsorMobileNumber &&
            paramsMobileNumber !== sponsorMobileNumber
        ) ||
        visitorPass.sponsorStatus !== 1
      ) {
        setLinkInvalid(true);
      }

      setSponsorName(visitorPass.sponsorName ?? "");
      setSponsorAsicId(visitorPass.sponsorAsicId ?? "");
      setSponsorAsicExpiryMonth(visitorPass.sponsorAsicExpiryMonth ?? "");
      setSponsorAsicExpiryYear(visitorPass.sponsorAsicExpiryYear ?? "");
      setSponsorCompany(visitorPass.sponsorCompany ?? "");
      setSponsorEmail(visitorPass.sponsorEmail ?? "");
      setSponsorMobileNumber(visitorPass.sponsorMobileNumber ?? "");
    } catch (error) {
      console.error("Error occurred getting the visitor pass - ", error);
    } finally {
      setLoading(false);
    }
  };

  const approveVisitorPassSponsor = async () => {
    try {
      setLoading(true);
      // Update VP first
      await updateVisitorPassAsSponsor();

      // Then approve
      await api.approveVisitorPassSponsorById(
        visitorPassId,
        sponsorEmail,
        sponsorMobileNumber
      );

      setApproveSuccess(true);
      setTimeout(() => navigate("/thank-you"), 3000);
    } catch (error) {
      console.error(
        "Error occurred while approving visitor pass as sponsor - ",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const declineVisitorPassAsSponsor = async () => {
    try {
      setLoading(true);
      // Update VP first
      await updateVisitorPassAsSponsor();

      // Then decline
      await api.declineVisitorPassAsSponsorById(
        visitorPassId,
        sponsorEmail,
        sponsorMobileNumber
      );

      setDeclineSuccess(true);
      setTimeout(() => navigate("/thank-you"), 3000);
    } catch (error) {
      console.error(
        "Error occurred while declining visitor pass as sponsor - ",
        error
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const updateVisitorPassAsSponsor = async () => {
    await api.updateVisitorPassAsSponsorById(visitorPassId, {
      sponsorName,
      sponsorAsicId,
      sponsorAsicExpiryMonth,
      sponsorAsicExpiryYear,
      sponsorCompany,
      sponsorEmail,
      sponsorMobileNumber,
      sponsorIdPhotoFront: idPhotoFront,
      sponsorFacePhoto: facePhoto,
    });
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto flex flex-row justify-center items-center sm:w-full sm:max-w-4xl">
          <img className="h-8 w-auto mr-1.5" src={G99Favicon} alt="Workflow" />
          <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900">
            {linkInvalid
              ? "This link is invalid or expired"
              : "Validate Visitor Pass"}
          </h2>
        </div>

        {!linkInvalid && (
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-4xl">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              <div className="">
                <nav
                  aria-label="Progress"
                  className="hidden overflow-x-auto scrollbar scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-100 md:flex max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3"
                >
                  <ol
                    role="list"
                    className="divide-y divide-gray-300 bg-white border border-gray-300 md:flex md:divide-y-0"
                  >
                    {steps.map((step, stepIdx) => (
                      <li
                        key={step.name}
                        className="cursor-pointer relative md:flex md:flex-1"
                      >
                        {step.status === "complete" ? (
                          <div className="group flex w-full items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-amber-600 group-hover:bg-amber-800">
                                <CheckIcon
                                  className="h-6 w-6 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-900">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        ) : step.status === "current" ? (
                          <div
                            className="flex items-center px-6 py-4 text-sm font-medium"
                            aria-current="step"
                          >
                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-amber-600">
                              <span className="text-white">{step.icon}</span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-amber-600">
                              {step.name}
                            </span>
                          </div>
                        ) : (
                          <div className="group flex items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                <span className="text-gray-500 group-hover:text-gray-900">
                                  {step.icon}
                                </span>
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                {step.name}
                              </span>
                            </span>
                          </div>
                        )}

                        {stepIdx !== steps.length - 1 ? (
                          <>
                            {/* Arrow separator for lg screens and up */}
                            <div
                              className="absolute top-0 right-0 hidden h-full w-5 md:block"
                              aria-hidden="true"
                            >
                              <svg
                                className="h-full w-full text-gray-300"
                                viewBox="0 0 22 80"
                                fill="none"
                                preserveAspectRatio="none"
                              >
                                <path
                                  d="M0 -2L20 40L0 82"
                                  vectorEffect="non-scaling-stroke"
                                  stroke="currentcolor"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </>
                        ) : null}
                      </li>
                    ))}
                  </ol>
                </nav>
                <div className="py-2 md:py-4">
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-0 sm:py-3 lg:grid lg:grid-cols-12 lg:gap-4">
                    <main className="lg:col-span-12 xl:col-span-10">
                      <h2 className="text-xl font-semibold mb-4 flex flex-row items-center">
                        <span className="h-6 w-auto mr-1.5">
                          {steps[currentStep - 1].icon}
                        </span>
                        {steps[currentStep - 1].name}
                      </h2>
                      <div className="md:hidden">
                        <div className="mt-6" aria-hidden="true">
                          <div className="overflow-hidden rounded-full bg-gray-200">
                            <div
                              className="h-2 rounded-full bg-amber-500"
                              style={{
                                width: `${(currentStep * 100) / steps.length}%`,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {steps[currentStep - 1].component ?? ""}
                      <div className="flex flex-col space-y-1 sm:space-y-0 sm:flex-row items-center mt-4 w-full">
                        {currentStep > 1 && (
                          <div className="col-span-3 w-full sm:w-fit mr-1.5 sm:mr-3">
                            <button
                              type="submit"
                              disabled={
                                loading ||
                                invalidForm ||
                                approveSuccess ||
                                declineSuccess
                              }
                              onClick={() =>
                                currentStep === 1
                                  ? navigate("/signin")
                                  : setCurrentStep(currentStep - 1)
                              }
                              className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50"
                            >
                              <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                              Previous
                            </button>
                          </div>
                        )}

                        {currentStep === 1 && (
                          <div className="col-span-3 w-full sm:w-fit mr-1.5 sm:mr-3">
                            <button
                              onClick={() => declineVisitorPassAsSponsor()}
                              disabled={
                                loading ||
                                invalidForm ||
                                approveSuccess ||
                                declineSuccess
                              }
                              className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                            >
                              <XIcon className="h-5 w-5 mr-1.5" />
                              Decline
                            </button>
                          </div>
                        )}

                        {currentStep === steps.length ? (
                          <>
                            <div className="col-span-3 w-full sm:w-fit mr-1.5 sm:mr-3">
                              <button
                                onClick={() => approveVisitorPassSponsor()}
                                disabled={
                                  loading ||
                                  invalidForm ||
                                  approveSuccess ||
                                  declineSuccess
                                }
                                className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                              >
                                <CheckIcon className="h-5 w-5 mr-1.5" />
                                Approve
                              </button>
                            </div>
                            <div className="col-span-3 w-full sm:w-fit mr-1.5 sm:mr-3">
                              <button
                                onClick={() => declineVisitorPassAsSponsor()}
                                disabled={
                                  loading ||
                                  invalidForm ||
                                  approveSuccess ||
                                  declineSuccess
                                }
                                className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                              >
                                <XIcon className="h-5 w-5 mr-1.5" />
                                Decline
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="col-span-3 w-full sm:w-fit mr-1.5 sm:mr-3">
                            <button
                              type="submit"
                              onClick={() => setCurrentStep(currentStep + 1)}
                              disabled={
                                loading ||
                                invalidForm ||
                                approveSuccess ||
                                declineSuccess
                              }
                              className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm sm:text-base font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                            >
                              <ArrowRightIcon className="h-5 w-5 mr-1.5" />
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </main>

                    {invalidForm && (
                      <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 sm:mt-0 mt-2">
                        <div className="my-1">
                          Please complete all required information (*) to
                          continue
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={approveSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully approved visitor pass
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setApproveSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={declineSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully declined visitor pass
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeclineSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
