import { useEffect } from "react";
import { showAsteriskIfEmpty } from '../../utils/helpers/asterisk';


interface IDDetailsProps {
  idType: string;
  setIDType: (newIDType: string) => void;
  idNumber: string;
  setIDNumber: (newIDNumber: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const IDDetails = (props: IDDetailsProps) => {
  useEffect(() => {
    if (props.idType === "Driver's License") {
      props.setInvalidForm(!props.idType || !props.idNumber);
    } else {
      props.setInvalidForm(!props.idType || !props.idNumber);
    }
  }, [props.idType, props.idNumber]);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label htmlFor="id-type" className="block text-xs font-medium text-gray-900">
              ID Type{showAsteriskIfEmpty(props.idType)}
            </label>
            <select
              id="id-type"
              name="id-type"
              value={props.idType}
              onChange={(e) => props.setIDType(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            >
              <option value="Driver's License">Driver's License</option>
              <option value="Passport">Passport</option>
              <option value="National ID">National ID</option>
            </select>
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange px-3 pb-1.5 pt-2.5">
            <label htmlFor="id-number" className="block text-xs font-medium text-gray-900">
              ID Number{showAsteriskIfEmpty(props.idNumber)}
            </label>
            <input
              id="id-number"
              name="id-number"
              type="text"
              autoComplete="new-id-number"
              required
              value={props.idNumber}
              onChange={(e) => props.setIDNumber(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
            />
          </div>
        </div>
      </div>
    </div>
  );
};