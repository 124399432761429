import { Dialog, Transition } from "@headlessui/react";
import { CameraIcon, RefreshIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useRef, useState } from "react";
import Webcam from "react-webcam";

interface PhotoModalProps {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
  action: (imageSrc: string) => any;
  title: string;
  description: string;
}

export default function PhotoModal(props: PhotoModalProps) {
  const [error, setError] = useState<string>("");
  const [facingMode, setFacingMode] = useState<string>("user");
  const webcamRef = useRef<any>();

  const capturePhoto = () => {
    try {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          props.action(imageSrc);
          props.setOpen(false);
        } else {
          setError("Loading webcam... please wait.");
        }
      } else {
        setError("Loading webcam... please wait.");
      }
    } catch (error) {
      setError(
        "An unknown error occurred. Please try again or contact support."
      );
    }

    setTimeout(() => setError(""), 2000);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-401 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900 items-center flex flex-row"
                    >
                      <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                        <CameraIcon
                          className="h-5 w-5 text-yellow-600"
                          aria-hidden="true"
                        />
                      </div>
                      <span className="ml-1.5">{props.title ?? "Photo"}</span>
                    </Dialog.Title>
                    <div className="mt-2 rounded-md">
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={1000}
                        height={1000}
                        videoConstraints={{ facingMode }}
                      />
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {props.description ??
                          "Are you sure you want to delete?"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex">
                  <button
                    type="button"
                    className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-amber-500 text-base font-medium text-white hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => capturePhoto()}
                  >
                    <CameraIcon className="h-5 w-5" />
                    <span className="ml-1.5">Capture</span>
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-stone-500 hover:bg-stone-600 text-base font-medium text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() =>
                      facingMode === "user"
                        ? setFacingMode("environment")
                        : setFacingMode("user")
                    }
                  >
                    <RefreshIcon className="h-5 w-5" />
                    <span className="ml-1.5">Flip</span>
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => props.setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
                {error && (
                  <div className="mt-4 text-red-600 text-sm">{error}</div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
