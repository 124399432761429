import { CogIcon, HomeIcon } from "@heroicons/react/outline";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import AccountSettings from "../../components/Settings/AccountSettings/AccountSettings";
import { classNames } from "../../utils/helpers/misc";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  const goToProfile = () => {
    navigate("/settings");
  };

  const navigation = [
    {
      name: "Home",
      onClick: goToDashboard,
      icon: HomeIcon,
      current: false,
    },
    { name: "Profile", onClick: goToProfile, icon: CogIcon, current: true },
  ];

  return (
    <div className="min-h-full">
      <NavBar />
      <div className="py-4 sm:py-10 fadeIn">
        <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
          <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
            <nav
              aria-label="Sidebar"
              className="sticky top-4 divide-y divide-gray-300"
            >
              <div className="pb-8 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    onClick={item.onClick}
                    className={classNames(
                      item.current
                        ? "bg-gray-200 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50",
                      "cursor-pointer group flex items-center px-3 py-2 font-medium rounded-md"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </div>
            </nav>
          </div>
          <main className="lg:col-span-12 xl:col-span-10">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
              <Routes>
                <Route path={"/account"} element={<AccountSettings />} />
                <Route path="/" element={<Navigate to="/settings/account" />} />
              </Routes>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
