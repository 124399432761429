import { useEffect } from "react";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { Link } from "react-router-dom";
import { showAsteriskIfEmpty } from '../../utils/helpers/asterisk';


interface PersonalDetailsProps {
  firstName: string;
  setFirstName: (newFirstName: string) => void;
  lastName: string;
  setLastName: (newLastName: string) => void;
  email: string;
  setEmail: (newEmail: string) => void;
  mobileNumber: string;
  setMobileNumber: (newMobileNumber: string) => void;
  company: string;
  setCompany: (newCompany: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const PersonalDetails = (props: PersonalDetailsProps) => {
  const auth = useAuth();

  useEffect(() => {
    props.setInvalidForm(
      !props.firstName ||
      !props.lastName ||
      !props.email ||
      !props.mobileNumber ||
      !props.company
    );
  }, [props.firstName, props.lastName, props.email, props.mobileNumber, props.company]);

  useEffect(() => {
    if (auth.user && !auth.loading) {
      api.updateClient(auth.token);
      props.setFirstName(auth.user.firstName ?? "");
      props.setLastName(auth.user.lastName ?? "");
      props.setCompany(auth.user.company ?? "");
      props.setMobileNumber(auth.user.phone ?? "");
      props.setEmail(auth.user.email ?? "");
    }
  }, [auth.loading]);

  return (
    <div className="mt-4 max-w-screen-sm">
      <div className="mb-3 text-sm text-gray-600">
        Review and confirm your personal details. If incorrect,{" "}
        <span className="font-semibold text-amber-500 hover:text-amber-600">
          <Link to={"/settings"}>update your details</Link>
        </span>
        .
      </div>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="first-name" className="block text-xs font-medium text-gray-900">
              First Name{showAsteriskIfEmpty(props.firstName)}
            </label>
            <input
              id="first-name"
              name="first-name"
              type="text"
              autoComplete="new-first-name"
              required
              value={props.firstName}
              onChange={(e) => props.setFirstName(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="last-name" className="block text-xs font-medium text-gray-900">
              Last Name{showAsteriskIfEmpty(props.lastName)}
            </label>
            <input
              id="last-name"
              name="last-name"
              type="text"
              autoComplete="new-last-name"
              required
              value={props.lastName}
              onChange={(e) => props.setLastName(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="email" className="block text-xs font-medium text-gray-900">
              Email Address{showAsteriskIfEmpty(props.email)}
            </label>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="new-email"
              required
              value={props.email}
              onChange={(e) => props.setEmail(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="mobile-number" className="block text-xs font-medium text-gray-900">
              Mobile Number{showAsteriskIfEmpty(props.mobileNumber)}
            </label>
            <input
              id="mobile-number"
              name="mobile-number"
              type="text"
              autoComplete="new-mobile-number"
              required
              value={props.mobileNumber}
              onChange={(e) => props.setMobileNumber(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-customOrange">
            <label htmlFor="company" className="block text-xs font-medium text-gray-900">
              Company{showAsteriskIfEmpty(props.company)}
            </label>
            <input
              id="company"
              name="company"
              type="text"
              autoComplete="new-company"
              required
              value={props.company}
              onChange={(e) => props.setCompany(e.target.value)}
              className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-transparent"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  );
};