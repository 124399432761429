import { CheckCircleIcon } from "@heroicons/react/outline";

export const ThankYou = () => {
  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <main className="mx-auto">
              <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                <CheckCircleIcon className="mx-auto text-green-500 h-12 w-auto" />
                <h2 className="mt-3 text-center text-2xl sm:text-3xl font-extrabold text-gray-900">
                  Thank You
                </h2>
                <div className="text-center my-4 sm:text-base text-sm text-gray-600">
                  We've informed the visitor of your response. If the visitor
                  requests sponsorship again, you'll receive a notification via
                  email or SMS.
                </div>
                <div className="text-center my-4 sm:text-base text-sm text-gray-600">
                  You can now close this window.
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
