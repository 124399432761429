import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { CreateVisitorPass } from "./pages/CreateVisitorPass/CreateVisitorPass";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import Settings from "./pages/Settings/Settings";
import SignIn from "./pages/SignIn/SignIn";
import SignOut from "./pages/SignOut/SignOut";
import SignUp from "./pages/SignUp/SignUp";
import { VerifyChangedEmail } from "./pages/VerifyChangedEmail/VerifyChangedEmail";
import { VerifyEmail } from "./pages/VerifyEmail/VerifyEmail";
import { VerifySponsor } from "./pages/VerifySponsor/VerifySponsor";
import { VisitorPassDetails } from "./pages/VisitorPassDetails/VisitorPassDetails";
import { AuthProvider, RequireAuth } from "./utils/auth/auth";
import { ThankYou } from "./pages/ThankYou/ThankYou";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/verify" element={<VerifyEmail />} />
          <Route
            path="/verify-changed-email"
            element={<VerifyChangedEmail />}
          />
          <Route path="/verify-sponsor" element={<VerifySponsor />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signout" element={<SignOut />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass/create"
            element={
              <RequireAuth>
                <CreateVisitorPass />
              </RequireAuth>
            }
          />
          <Route
            path="/visitor-pass/details/:visitorPassId"
            element={
              <RequireAuth>
                <VisitorPassDetails />
              </RequireAuth>
            }
          />
          <Route
            path="/settings/*"
            element={
              <RequireAuth>
                <Settings />
              </RequireAuth>
            }
          />
          <Route path="/" element={<Navigate to="/signin" />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
