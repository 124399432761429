import { AxiosError } from "axios";
import debounce from "lodash.debounce";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import G99Favicon from "../../assets/img/g99-favicon.png";
import SignInImg from "../../assets/img/signin-img.jpg";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";

export default function SignUp() {
  const navigate = useNavigate();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSuggestedAddress, setLoadingSuggestedAddress] =
    useState<boolean>(false);
  const [invalidRegister, setInvalidRegister] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [suggestedAddress, setSuggestedAddress] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [regErrorMessages, setRegErrorMessages] = useState<string[]>([]);

  const signUpWithEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (email && password) {
      auth
        .signUpWithEmail(
          email,
          password,
          firstName,
          lastName,
          mobileNumber,
          company,
          dob,
          address
        )
        .then(() => {
          setLoading(false);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 409) {
            setRegErrorMessages([
              ...regErrorMessages,
              "Email already in use. Please sign into your existing account.",
            ]);
          } else {
            setRegErrorMessages([
              ...regErrorMessages,
              "An unknown error occurred while registering",
            ]);
            console.error(error);
          }
          setTimeout(() => setRegErrorMessages([]), 3000);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const newSignInErrorMessages = regErrorMessages.filter(
      (m) => m !== "Both passwords must match"
    );

    setInvalidRegister(false);

    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobileNumber ||
      !dob ||
      !address ||
      !password ||
      !confirmPassword
    ) {
      setInvalidRegister(true);
    }

    if (password && password !== confirmPassword) {
      setRegErrorMessages([
        ...newSignInErrorMessages,
        "Both passwords must match",
      ]);
      setInvalidRegister(true);
    } else {
      setRegErrorMessages(newSignInErrorMessages);
    }
  }, [
    email,
    password,
    mobileNumber,
    firstName,
    lastName,
    address,
    confirmPassword,
  ]);

  const changeAddress = async (newAddress: string) => {
    setAddress(newAddress);
    setLoadingSuggestedAddress(true);
    // Validate address
    if (newAddress && newAddress !== suggestedAddress) {
      debouncedValidateAddress(newAddress);
    } else {
      setSuggestedAddress("");
    }
  };

  const debouncedValidateAddress = React.useRef(
    debounce(async (addressToValidate: string) => {
      const addressRes = await api.validateVisitorAddress(addressToValidate);
      setSuggestedAddress(addressRes.data);
      setLoadingSuggestedAddress(false);
    }, 1000)
  ).current;

  useEffect(() => {
    if (auth.user && !auth.loading) {
      navigate("/dashboard", { replace: true });
    }
    setLoading(false);
  }, [auth.loading]);

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto mx-auto"
                src={G99Favicon}
                alt="Visitor Pass Favicon"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900 ">
                Register for Visitor Pass
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form
                  className="grid grid-cols-6 gap-6"
                  onSubmit={(e) => signUpWithEmail(e)}
                >
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="first-name"
                        name="first-name"
                        type="text"
                        autoComplete="new-first-name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="last-name"
                        name="last-name"
                        type="text"
                        autoComplete="new-last-name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="new-email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="dob"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Birth
                    </label>
                    <div className="mt-1">
                      <input
                        id="dob"
                        name="dob"
                        type="date"
                        autoComplete="new-dob"
                        onChange={(e) => setDob(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="mobile-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Mobile Number
                    </label>
                    <div className="mt-1">
                      <input
                        id="mobile-number"
                        name="mobile-number"
                        type="text"
                        autoComplete="new-mobile-number"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company
                    </label>
                    <div className="mt-1">
                      <input
                        id="company"
                        name="company"
                        type="text"
                        autoComplete="new-company"
                        onChange={(e) => setCompany(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="address"
                        name="address"
                        type="text"
                        autoComplete="new-address"
                        value={address}
                        onChange={(e) => changeAddress(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                    {loadingSuggestedAddress ? (
                      <label
                        htmlFor="company"
                        className="mt-1.5 block text-xs font-medium text-gray-700"
                      >
                        Loading suggested address...
                      </label>
                    ) : (
                      suggestedAddress &&
                      suggestedAddress !== address && (
                        <>
                          <label
                            htmlFor="company"
                            className="mt-1.5 block text-xs font-medium text-gray-700"
                          >
                            Suggested
                          </label>
                          <div
                            onClick={() => {
                              setAddress(suggestedAddress);
                              setSuggestedAddress("");
                            }}
                            className="mt-1 px-3 py-2 bg-white border border-gray-300 text-sm rounded-md shadow-sm hover:bg-indigo-600 hover:text-white cursor-pointer"
                          >
                            {suggestedAddress}
                          </div>
                        </>
                      )
                    )}
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="confirm-password"
                        name="confirm-password"
                        type="password"
                        autoComplete="confirm-password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="col-span-6">
                    <button
                      type="submit"
                      disabled={loading || invalidRegister}
                      className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                    >
                      {loading && (
                        <svg
                          aria-hidden="true"
                          className="inline w-4 h-4 mr-2 text-gray-200 animate-spin fill-blue-600"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      )}
                      Register
                    </button>
                    <div className="block text-sm text-center font-medium text-red-500 mt-4">
                      {regErrorMessages.map((message) => {
                        return <div className="my-1">{message}</div>;
                      })}
                    </div>
                  </div>
                </form>
                <p className="text-sm text-gray-600 text-center">
                  Or{" "}
                  <Link to={"/signin"}>
                    <span className="font-medium text-amber-600 hover:text-amber-500">
                      sign in with an existing account
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={SignInImg}
            alt=""
          />
        </div>
      </div>
    </>
  );
}
