import { useEffect, useState } from "react";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";

interface ReviewPassProps {
  visitorPassId: string;
  sponsorDetailsHidden?: boolean;
}

export const ReviewPass = (props: ReviewPassProps) => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] =
    useState<string>("1");
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    new Date().getFullYear().toString()
  );
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [airport, setAirport] = useState<any>();
  const [rangeType, setRangeType] = useState<string>("day");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    api.updateClient(auth.token);

    if (props.visitorPassId) {
      fetchVisitorPass();
    }
  }, [props.visitorPassId]);

  const fetchVisitorPass = async () => {
    try {
      const visitorPassResponse = await api.getVisitorPassById(
        props.visitorPassId
      );
      const visitorPass = visitorPassResponse.data;
      setFirstName(visitorPass.firstName ?? "");
      setLastName(visitorPass.lastName ?? "");
      setCompany(visitorPass.company ?? "");
      setMobileNumber(visitorPass.mobileNumber ?? "");
      setEmail(visitorPass.email ?? "");
      setOperationalNeed(visitorPass.operationalNeed ?? "");
      setSponsorName(visitorPass.sponsorName ?? "");
      setSponsorAsicId(visitorPass.sponsorAsicId ?? "");
      setSponsorAsicExpiryMonth(visitorPass.sponsorAsicExpiryMonth ?? "");
      setSponsorAsicExpiryYear(visitorPass.sponsorAsicExpiryYear ?? "");
      setSponsorCompany(visitorPass.sponsorCompany ?? "");
      setRangeType(visitorPass.rangeType ?? "");
      setStartTime(visitorPass.startTime.substring(0, 16) ?? "");
      setEndTime(visitorPass.endTime.substring(0, 16) ?? "");
      setAirport(visitorPass.airport);

      setLoading(false);

      if (visitorPass.facePhoto) {
        api
          .getVisitorPassFileByPath(visitorPass.id, visitorPass.facePhoto)
          .then((response) => {
            setFacePhoto(response.data.url);
          })
          .catch((error) => {
            console.error("Error in getVisitorPassFileByPath - ", error);
          });
      }
    } catch (error) {
      console.error("Error occurred getting the visitor pass - ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-8">
      <div className="text-sm text-gray-700 mb-4 sm:mt-0 mt-4">
        Please verify the following information is correct before
        approving/declining the visitor pass.
      </div>
      {loading ? (
        <div className="mt-4 text-sm text-gray-500">Loading...</div>
      ) : (
        <>
          <div className="mt-4 max-w-screen-sm text-sm">
            <div>
              <span className="font-semibold mr-1.5">Airport:</span>
              {airport ? `${airport.name}` : "No Airport Specified"}
            </div>
          </div>
          <div className="mt-4 max-w-screen-sm">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <div className="font-semibold text-lg mb-2">
                  Request Details
                </div>
                <label className="inline-block mb-2 text-gray-500">
                  Face Photo
                </label>
                <div className="flex items-center justify-center w-full">
                  <label className="flex flex-col w-full h-fit border-4  hover:bg-gray-100 rounded-lg">
                    {facePhoto ? (
                      <div className="flex flex-col items-center justify-center">
                        <img
                          className="w-full h-full rounded-md"
                          src={facePhoto}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-center py-7">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          Loading image
                        </p>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="hidden sm:block sm:col-span-3"></div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <div className="mt-1">
                  <input
                    id="first-name"
                    name="first-name"
                    type="text"
                    disabled
                    autoComplete="new-first-name"
                    required
                    value={firstName}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <div className="mt-1">
                  <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="new-last-name"
                    disabled
                    value={lastName}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="new-email"
                    disabled
                    value={email}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="mobile-number"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mobile Number
                </label>
                <div className="mt-1">
                  <input
                    id="mobile-number"
                    name="mobile-number"
                    type="text"
                    autoComplete="new-mobile-number"
                    disabled
                    value={mobileNumber}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    autoComplete="new-company"
                    disabled
                    value={company}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="operational-need"
                  className="block text-sm font-medium text-gray-700"
                >
                  Operational Need
                </label>
                <div className="mt-1">
                  <input
                    id="operational-need"
                    name="operational-need"
                    type="text"
                    autoComplete="new-operational-need"
                    disabled
                    value={operationalNeed}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="pass-duration"
                  className="block text-sm font-medium text-gray-700"
                >
                  Pass Duration
                </label>
                <select
                  id="pass-duration"
                  name="pass-duration"
                  autoComplete="pass-duration"
                  value={rangeType}
                  disabled
                  onChange={(e) => setRangeType(e.target.value)}
                  className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                >
                  <option>24 Hours</option>
                  <option>1 Week</option>
                  <option>1 Month</option>
                  <option>Custom</option>
                </select>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="start-time"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Time
                </label>
                <div className="mt-1">
                  <input
                    id="start-time"
                    name="start-time"
                    type="datetime-local"
                    autoComplete="new-start-time"
                    value={startTime}
                    step={60 * 15}
                    disabled
                    onChange={(e) => setStartTime(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="end-time"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Time
                </label>
                <div className="mt-1">
                  <input
                    id="end-time"
                    name="end-time"
                    type="datetime-local"
                    autoComplete="new-end-time"
                    value={endTime}
                    disabled
                    onChange={(e) => setEndTime(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                  />
                </div>
              </div>

              {!props.sponsorDetailsHidden && (
                <>
                  <div className="col-span-6 font-semibold text-lg">
                    Sponsor Details
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="sponsor-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="sponsor-name"
                        name="sponsor-name"
                        type="text"
                        autoComplete="new-sponsor-name"
                        disabled
                        value={sponsorName}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                      />
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="sponsor-asic-id"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor ASIC ID
                    </label>
                    <div className="mt-1">
                      <input
                        id="sponsor-asic-id"
                        name="sponsor-asic-id"
                        type="text"
                        autoComplete="new-sponsor-asic-id"
                        disabled
                        value={sponsorAsicId}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                      />
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="sponsor-asic-expiry-month"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor ASIC Expiry Month
                    </label>
                    <div className="mt-1">
                      <select
                        id="sponsor-asic-expiry-month"
                        name="sponsor-asic-expiry-month"
                        autoComplete="new-sponsor-asic-expiry-month"
                        value={sponsorAsicExpiryMonth}
                        onChange={(e) =>
                          setSponsorAsicExpiryMonth(e.target.value)
                        }
                        disabled
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                      >
                        <option value={1}>Jan</option>
                        <option value={2}>Feb</option>
                        <option value={3}>Mar</option>
                        <option value={4}>Apr</option>
                        <option value={5}>May</option>
                        <option value={6}>Jun</option>
                        <option value={7}>Jul</option>
                        <option value={8}>Aug</option>
                        <option value={9}>Sep</option>
                        <option value={10}>Oct</option>
                        <option value={11}>Nov</option>
                        <option value={12}>Dec</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="sponsor-asic-expiry-year"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor ASIC Expiry Year
                    </label>
                    <div className="mt-1">
                      <select
                        id="sponsor-asic-expiry-year"
                        name="sponsor-asic-expiry-year"
                        autoComplete="new-sponsor-asic-expiry-year"
                        value={sponsorAsicExpiryYear}
                        onChange={(e) =>
                          setSponsorAsicExpiryYear(e.target.value)
                        }
                        disabled
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                      >
                        <option>{new Date().getFullYear()}</option>
                        <option>{new Date().getFullYear() + 1}</option>
                        <option>{new Date().getFullYear() + 2}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="sponsor-company"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sponsor Company
                    </label>
                    <div className="mt-1">
                      <input
                        id="sponsor-company"
                        name="sponsor-company"
                        type="text"
                        autoComplete="new-sponsor-company"
                        disabled
                        value={sponsorCompany}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};